import 'whatwg-fetch';

import { Suspense, lazy } from 'react';
import { render } from 'react-dom';

// lazy-load the application otherwise we cannot detect if we are running in IE11 Protected mode
// don't use Loadable here so we can reduce the chunk size. Use React.Suspense instead.
const App = lazy(() => import('src/components/App'));

try {
  render(
    <Suspense fallback={null}>
      <App />
    </Suspense>,
    document.getElementById('root')
  );
} catch (error) {
  render(
    <>
      <p>Unable to render the application.</p>
      <pre>User Agent = {navigator.userAgent}</pre>
      <pre>
        For details of your browser engine click <a href="/debug.html">here</a>.
      </pre>
      <br />
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </>,
    document.getElementById('root')
  );
  console.error(error);
}
